body{
  padding-bottom:50px;
}
.section-1{
  float: left;
  width: 40%;
  padding: 10px;
  align-items: center;
}
.box{
  border: 1px solid gray;
  box-shadow: 2px 2px 2px 2px;
  height: 400px;
  width: 400px;
  border-radius: 10px;
  margin-top: 90px;
  /* margin-left: 500px; */
}
.form{
  /* display: flex; */
  text-align: inherit;
  width: 100%;
  margin: auto;
  justify-content: center;
}
.ground-field{
  margin-top: 2%;
  width: 50%;
}
.tab{
  margin-top: 2%;
}
.submit-login{
  margin-top: 8%;
  align-items: center;
}
/* .section-1{
  padding: 1%;
  display: inline-table;
} */
.footer {
  bottom: 0;
  margin: 0 auto !important;
  text-align: center !important;
  width: 100% !important;
  height: 40px;
  position: fixed;
  background-color: rgb(189, 188, 188);
}
.home{
  padding-left: 150px;
  margin: 100px;
}
.copy{
  text-align: right;
}
.main-logo-logoin-page{
  display: flex;
  margin: auto;
  width: fit-content;
  align-items: center;
  padding-top: 5%
}
.logout-btn{
  align-items: center;
  /* margin-left: 92%;
  margin-top: -5%; */
  right:15px;
  top:15px;
  position: absolute;
}
.submit-btn{
  text-align: center;
  margin-bottom: 100px;
}
.odometer{
  width: 70%;
}
.main-box{
  display: flex !important;
  text-align: inherit;
  width: 100%;
  margin: auto;
  justify-content: center;
}
.ground-submit{
  right: 50%;
  left: 50;
 text-align: center;
 margin: auto;
}
.ground-btn{
  float: right;
  height: 40%;
}
.home-btn{
  float: left;
  height: 40%;
}
@media only screen and (max-width: 900px) {
  /* .submit-btn{
   margin-left: 40%; 
   margin-top: 0;
  } */
  .upload-btn{
    width:10%;
  }
  .section-1{
    width: 100%;
  }
  .cloud-text{
    text-align: center;
  }
}
/* Text area styles start here*/
/* Custom styles for the container */
.instructions-container {
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

/* Custom styles for the Text component (optional) */
.instructions-text {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Custom styles for the HTML content */
.instructions-html {
  font-size: 14px;
  line-height: 1.6;
  color: #555;
}
/* Text area styles end here*/

.container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* Additional styles for the container, if needed */
}

.container-login {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}